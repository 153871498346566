<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title >
            <img v-if="storegiftlistcategory.stor_glc_icone" :src="`${base_url}upload/store_gift_list_category/i/${storegiftlistcategory.stor_glc_icone}`" width="40">
            <img v-if="!storegiftlistcategory.stor_glc_icone" :src="`${base_url}upload/imagem.png`" width="40">
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h1 class="lime--text lighten-1">{{ storegiftlistcategory.stor_glc_nome }}</h1>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'store-gift-list-categories'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Código:</th>
                <td width="90%">{{ storegiftlistcategory.stor_glc_codigo }}</td>
            </tr>
            <tr>
                <th width="10%">Nome:</th>
                <td width="90%">{{ storegiftlistcategory.stor_glc_nome }}</td>
            </tr>
            <tr v-if="storegiftlistcategory.categoria">
                <th width="10%">Categoria:</th>
                <td width="90%">{{ storegiftlistcategory.categoria.prod_c_nome }}</td>
            </tr>
            <tr>
                <th width="10%">Link:</th>
                <td width="90%">{{ storegiftlistcategory.stor_glc_link }}</td>
            </tr>
            <tr>
                <th width="10%">Ordem:</th>
                <td width="90%">{{ storegiftlistcategory.stor_glc_ordem }}</td>
            </tr>
            <tr>
                <th width="10%">Status:</th>
                <td width="90%">{{ (storegiftlistcategory.stor_glc_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Mostrar no menu:</th>
                <td width="90%">{{ (storegiftlistcategory.stor_glc_show_menu == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Icone:</th>
                <td v-if="storegiftlistcategory.stor_glc_icone" width="10%">
                    <img :src="`${base_url}upload/store_gift_list_category/i/${storegiftlistcategory.stor_glc_icone}`" width="60">
                </td>
                <td v-if="!storegiftlistcategory.stor_glc_icone" width="10%">
                    <img :src="`${base_url}upload/imagem.png`">
                </td>
            </tr>
            <tr>
                <th width="10%">Banner:</th>
                <td v-if="storegiftlistcategory.stor_glc_banner" width="10%">
                    <img :src="`${base_url}upload/store_gift_list_category/b/${storegiftlistcategory.stor_glc_banner}`" width="400">
                </td>
                <td v-if="!storegiftlistcategory.stor_glc_banner" width="10%">
                    <img :src="`${base_url}upload/imagem.png`">
                </td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailStoreGiftListCategory",
    created() {
        this.loadStoreGiftListCategory()
    },
    props: {
        stor_glc_id: {
            require: true
        },
    },
    data() {
        return {
            search: '',
            storegiftlistcategory: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadStoreGiftListCategory() {
            this.$store.dispatch('loadStoreGiftListCategory', this.stor_glc_id)
                .then(response => {
                    this.storegiftlistcategory = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}
</style>
